<ng-container
  *ngIf="design"
  [ngTemplateOutlet]="DESIGN_2"
></ng-container>
<ng-template #DESIGN_2>
  <app-page-header
    title="Please fill in your information."
    [hasBack]="true"
    (onBack)="back()"
  ></app-page-header>
  <div class="page-content has-back" fxLayout="column">
    <div
      class="donation-info step-content"
      fxLayoutAlign="center center"
      fxLayoutAlign.xs="center start"
      fxLayout="column"
    >
      <div fxLayout="column" fxLayoutAlign="center center">
        <app-info-form [form]="form"></app-info-form>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center">
        <button class="primary-btn warn-btn filled-btn" (click)="onConfirm()"
        [ladda]="isSubmitting"
      >
        Confirm
        </button>
      </div>
    </div>
  </div>
</ng-template>
