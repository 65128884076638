<div
  class="questions"
  *ngIf="showQuestions"
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <form [formGroup]="form">
    <div
      class="charity-logo-wrapper"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <img [src]="charity.logo" />
    </div>
    <h3>
      Please answer the following questions to determine if you are eligible for
      a standard donation pickup by {{ charity.name }}
    </h3>
    <div
      class="form-row"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="space-between center"
      [ngClass]="{ invalid: zip.invalid && zip.touched }"
      *ngIf="
        (screening?.zips?.length || 0) > 0 &&
        !screening?.hiddenFields?.includes(question.ZIPS)
      "
    >
      <mat-label>Please enter your ZIP Code</mat-label>
      <div fxLayout="column">
        <input
          type="text"
          #nameInput
          formControlName="zip"
          placeholder="ZIP Code"
          style="width: 120px; font-size: 18px"
          (change)="inputChange(inputActivity.SCREENING_ZIP, zip.value)"
        />
        <mat-error *ngIf="zip.invalid && zip.touched">
          Zip is not valid
        </mat-error>
      </div>
    </div>
    <div
      class="form-row"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="space-between center"
      [ngClass]="{ invalid: stairs.invalid && stairs.touched }"
      *ngIf="
        screening?.floor &&
        screening?.floor !== 'any' &&
        !screening?.hiddenFields?.includes(question.FLOOR)
      "
    >
      <mat-label *ngIf="screening?.floor === 'curbside'">
        Will movers have to go inside your home in order to transport your
        items?
      </mat-label>
      <mat-label *ngIf="screening?.floor === 'first'">
        Will movers have to go up or down flights of stairs in order to
        transport your items?
      </mat-label>
      <mat-label *ngIf="screening?.floor === 'second'">
        Will movers have to go up or down flights of stairs above the second
        floor in order to transport your items?
      </mat-label>
      <mat-button-toggle-group
        (valueChange)="
          stairs.setValue($event);
          inputChange(
            inputActivity.SCREENING_FLOOR,
            stairs.value ? 'Yes' : 'No'
          )
        "
      >
        <mat-button-toggle
          [checked]="stairs.value === true"
          [value]="true"
          fxFlex="50"
          fxLayoutAlign="center center"
          class="green"
          >Yes
        </mat-button-toggle>
        <mat-button-toggle
          [checked]="stairs.value === false"
          [value]="false"
          fxFlex="50"
          fxLayoutAlign="center center"
          class="red"
          >No
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div
      class="form-row"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="space-between center"
      [ngClass]="{ invalid: disassembly.invalid && disassembly.touched }"
      *ngIf="
        screening?.disassembly === false &&
        !screening?.hiddenFields?.includes(question.DISASSEMBLY)
      "
    >
      <mat-label>
        Will any of your items need to be disassembled to be removed from your
        home?
      </mat-label>
      <mat-button-toggle-group
        (valueChange)="
          disassembly.setValue($event);
          inputChange(
            inputActivity.SCREENING_DISASSEMBLY,
            disassembly.value ? 'Yes' : 'No'
          )
        "
      >
        <mat-button-toggle
          [checked]="disassembly.value === true"
          [value]="true"
          fxFlex="50"
          fxLayoutAlign="center center"
          class="green"
          >Yes
        </mat-button-toggle>
        <mat-button-toggle
          [checked]="disassembly.value === false"
          [value]="false"
          fxFlex="50"
          fxLayoutAlign="center center"
          class="red"
          >No
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div
      class="form-row"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="space-between center"
      [ngClass]="{
        invalid: restrictedItems.invalid && restrictedItems.touched
      }"
      *ngIf="
        (screening?.restrictedItems?.length || 0) > 0 &&
        !screening?.hiddenFields?.includes(question.RESTRICTED_ITEMS)
      "
    >
      <mat-label>
        Are you donating any items on this list:
        <i>{{ screening?.restrictedItems?.join(', ') }}</i> ?
      </mat-label>
      <mat-button-toggle-group
        (valueChange)="
          restrictedItems.setValue($event);
          inputChange(
            inputActivity.SCREENING_RESTRICTED_ITEMS,
            restrictedItems.value ? 'Yes' : 'No'
          )
        "
      >
        <mat-button-toggle
          [checked]="restrictedItems.value === true"
          [value]="true"
          fxFlex="50"
          fxLayoutAlign="center center"
          class="green"
          >Yes
        </mat-button-toggle>
        <mat-button-toggle
          [checked]="restrictedItems.value === false"
          [value]="false"
          fxFlex="50"
          fxLayoutAlign="center center"
          class="red"
          >No
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div
      class="form-row"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="space-between center"
      [ngClass]="{ invalid: damaged.invalid && damaged.touched }"
      *ngIf="!screening?.hiddenFields?.includes(question.DAMAGED)"
    >
      <mat-label>
        Do your items have any rips, stains, tears, smoking or pet damage?
      </mat-label>
      <mat-button-toggle-group
        (valueChange)="
          damaged.setValue($event);
          inputChange(
            inputActivity.SCREENING_DAMAGED,
            damaged.value ? 'Yes' : 'No'
          )
        "
      >
        <mat-button-toggle
          [checked]="damaged.value === true"
          [value]="true"
          fxFlex="50"
          fxLayoutAlign="center center"
          class="green"
          >Yes
        </mat-button-toggle>
        <mat-button-toggle
          [checked]="damaged.value === false"
          [value]="false"
          fxFlex="50"
          fxLayoutAlign="center center"
          class="red"
          >No
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div
      fxLayout="row"
      fxLayoutGap="5px"
      fxLayoutAlign="center center"
      fxFlexFill="100"
    >
      <button class="primary-btn" (click)="goBack()" fxFlex="50">Cancel</button>
      <button class="primary-btn filled-btn" (click)="next()" fxFlex="50">
        Next Step
      </button>
    </div>
  </form>
</div>
<div
  class="options"
  fxLayout="column"
  fxLayoutAlign="center center"
  *ngIf="!showQuestions"
>
  <div fxLayout="column" fxLayoutAlign="center center">
    <div class="charity-logo-wrapper" fxLayoutAlign="start center">
      <img [src]="charity.logo" />
    </div>
    <p class="text-center">
      Unfortunately your answers to the previous questions do not fit the
      standard pickup guidelines for {{ charity.name }}. It is unlikely that
      your standard pickup request will be accepted.
      <span *ngIf="charity.meta?.dropOffUrl">
        You can drop off your donation at {{ charity.name }}
        <a class="learn-more" href="charity.meta?.dropOffUrl">here</a>.</span
      >
      However, you may still submit a request for pickup approval.
    </p>
    <div
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="center center"
      fxFlexFill="100"
    >
      <button
        class="primary-btn filled-btn"
        (click)="goToFree(true)"
        fxFlex="100"
      >
        Request Exception for Unqualified Pickup
      </button>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="center center" class="priority-option">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <mat-icon class="logo" svgIcon="rspl-new-logo"></mat-icon>
      <mat-icon>arrow_right_alt</mat-icon>
      <div class="charity-logo-wrapper" fxLayoutAlign="start center">
        <img [src]="charity.logo" />
      </div>
    </div>
    <button
      class="primary-btn filled-btn btn-block success-btn"
      (click)="goToPrimary()"
      cdkFocusInitial
    >
      Schedule Resupply Priority Pickup
    </button>
    <p class="text-center">
      {{ charity.name }} has partnered with Resupply to do specialty priority
      donation pickups. Click the button above to schedule a fee based donation
      pickup for your items to {{ charity.name }}
    </p>
    <span class="learn-more" (click)="showVideo()"
      >Not Sure? Click here to learn the details of Resupply Priority
      Pickups.</span
    >
    <youtube-player
      style="margin: 20px 0"
      *ngIf="videoVisible"
      videoId="FYqsbNxuTUI"
      suggestedQuality="highres"
      [width]="videoWidth"
      [height]="200"
    >
    </youtube-player>
    <div fxLayout="row" fxLayoutAlign="center" class="priority-bullets">
      <ul class="priority-list" fxLayout="column" fxLayoutGap="10px">
        <li fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <mat-icon svgIcon="ss-primary-floor"></mat-icon>
          <span>Pickups from any floor</span>
        </li>
        <li fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <mat-icon svgIcon="ss-primary-disassembly"></mat-icon>
          <span>Item disassembly and removal</span>
        </li>
        <li fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <mat-icon svgIcon="ss-primary-items"></mat-icon>
          <span>All items are picked up for a fee</span>
        </li>
        <li fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <mat-icon svgIcon="ss-primary-time"></mat-icon>
          <span>Donation pickups in 1-3 days</span>
        </li>
      </ul>
    </div>
  </div>
</div>
