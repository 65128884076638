import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Design } from '@domains';
import { DesignService } from '@rspl-ui';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SuccessComponent implements OnInit {
  public donationCode = '';
  message = '';
  title = '';
  hasLink: boolean;

  constructor(private route: ActivatedRoute, designService: DesignService) {
    designService.setDesign(Design.DESIGN_2);
    this.donationCode = this.route.snapshot.params['code'];
    this.hasLink = !!this.route.snapshot.data['hasLink'];
  }

  ngOnInit(): void {
    this.message =
      `Your donation <br><b>${this.donationCode}</b><br/>` +
      (this.route.snapshot.data['message'] ||
        `is on it's way to the <br/>charity!`);
    this.title =
      this.route.snapshot.data['title'] ||
      'Thank you<br/> for using<br/> ReSupply!';
  }
}
