import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ButtonActivity } from '@domains';
import { AppService } from '@donor/app.service';
import { InfoDialogComponent } from '@donor/components/info-dialog/info-dialog.component';
import { DesignService, Designable, ResponsiveService } from '@rspl-ui';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-donation-spec-item',
  templateUrl: './donation-spec-item.component.html',
  styleUrls: ['./donation-spec-item.component.scss'],
})
export class DonationSpecItemComponent extends Designable {
  @Input() itemTitle = '';
  @Input() subtitle = '';
  @Input() description = '';
  @Input() icon = 'xl';
  @Input() count = 0;
  @Input() key = '';
  @Input() disabled = false;
  @Input() leadId?: string;
  @Input() activity!: ButtonActivity;
  @Output() specChanged = new EventEmitter();
  @Input() editable = false;
  @Input() max = 99;
  @Input() min = 0;

  constructor(
    private dialog: MatDialog,
    protected override designService: DesignService,
    public override responsiveService: ResponsiveService,
    private service: AppService
  ) {
    super(designService, responsiveService);
  }

  updateItem(event: Event): void {
    if (this.disabled) return;
    this.count = this.checkValue(event);
    console.log(this.count);
    
    this.specChanged.emit({ [this.key]: this.count });
    this.service
      .createLeadActivity(this.leadId, this.activity, this.count)
      .pipe(take(1))
      .subscribe();
  }

  addItem(): void {
    if (this.disabled || this.count === this.max) return;
    this.count = (this.count || 0) + 1;
    this.specChanged.emit({ [this.key]: this.count });
    this.service
      .createLeadActivity(this.leadId, this.activity, this.count)
      .pipe(take(1))
      .subscribe();
  }

  removeItem(): void {
    if (this.disabled || (this.count || 0) === 0 || this.count === this.min) return;
    if ((this.count || 0) > 0) {
      this.count = (this.count || 0) - 1;
      this.specChanged.emit({ [this.key]: this.count });
    }
    this.service
      .createLeadActivity(this.leadId, this.activity, this.count)
      .pipe(take(1))
      .subscribe();
  }

  openInfoDialog() {
    if (this.description.trim().length === 0) return;
    this.dialog.open(InfoDialogComponent, {
      data: {
        title: this.itemTitle,
        icon: 'info',
        subtitle: this.subtitle,
        message: this.description,
        button: 'I understand.',
      },
    });
  }

  checkValue(event: any) {
    const val = Number(event.target.value.replaceAll(/[^\d]/g, ''));
    return isNaN(val) || val === undefined ? 0 : val;
  }

  focusInput(event: any) {
    event.target.select()
  }
}
