<ng-container *ngIf="design" [ngTemplateOutlet]="DESIGN_2"></ng-container>
<ng-template #DESIGN_2>
  <app-page-header title="Cancel your donation" [hasBack]="true" (onBack)="back()"></app-page-header>
  <div class="page-content has-back" fxLayout="column">
    <div fxLayout="column" class="cancel-wrapper">
      <div fxLayout="column">
        <h2>Are you sure you want to cancel your donation?</h2>
        <p>Please choose a reason why you are canceling</p>
      </div>
      <div fxLayout="column" class="fields">
        <div class="form-row" fxLayout="column" [ngClass]="{ invalid: showErrors && reason.invalid}">
          <mat-select fxFlex="100" matInput [formControl]="reason" panelClass="pick-us-state"
            disableOptionCentering="true" placeholder="Reason*" required [errorStateMatcher]="errorStateMatcher" #select
            (selectionChange)="setSelectedReason()">
            <mat-option *ngFor="let option of reasons" [value]="option">
              {{option.reason}}
            </mat-option>
          </mat-select>
          <span class="error-message" *ngIf="showErrors && reason.invalid">Reason is required</span>
        </div>
        <div class="form-row" fxLayout="column" [ngClass]="{ invalid: showErrors && subReason.invalid}">
          <mat-select fxFlex="100" *ngIf="selectedReason?.children" matInput [formControl]="subReason"
            panelClass="pick-us-state" disableOptionCentering="true" placeholder="Sub-Reason*" required>
            <mat-option *ngFor="let option of selectedReason?.children" [value]="option">
              {{option}}
            </mat-option>
          </mat-select>
          <span class="error-message" *ngIf="showErrors && selectedReason?.children && subReason.invalid">
            Sub-Reason is required
          </span>
        </div>
      </div>
      <mat-form-field class="notes" appearance="outline"
        [ngClass]="{invalid: showErrors && !additionalText.value?.trim()?.length}">
        <mat-label>Note</mat-label>
        <textarea matInput [formControl]="additionalText"></textarea>
        <mat-error>A note is required</mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 30px;">
      <button class="primary-btn warn-btn" (click)="onCancel()" [ladda]="isSubmitting">
        Cancel donation
      </button>
    </div>
  </div>
</ng-template>