<div fxLayout="row" fxLayoutGap="5px">
  <a
    [fxFlex]="100 / steps.length"
    *ngFor="let s of steps"
    [ngClass]="{ active: step === s.percent, filled: step >= s.percent }"
    [routerLink]="step >= s.percent ? getRoute(s) : undefined"
  >
  {{ s.percent + 1 }}{{'. ' + s.name}}
  </a>
</div>
