import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdditionalInformationComponent } from '@donor/screens/create/additional-information/additional-information.component';
import { DonationDetailsComponent } from '@donor/screens/create/donation-details/donation-details.component';
import { PaymentComponent } from '@donor/screens/create/payment/payment.component';
import { CreatedComponent } from '@donor/screens/created/created.component';
import { EditComponent } from '@donor/screens/edit/edit.component';
import { StateComponent } from '@donor/screens/manage/state/state.component';
import { NotFoundComponent } from '@donor/screens/not-found/not-found.component';
import { HealthCheckComponent } from '@rspl-ui';
import { ConfirmComponent } from './screens/confirm/confirm.component';
import { PlaceTimeComponent } from './screens/create/place-time/place-time.component';
import { SplitScreenPageComponent } from './screens/create/split-screen-page/split-screen-page.component';
import { DonationsComponent } from './screens/donations/donations.component';
import { FailComponent } from './screens/fail/fail.component';
import { LookupComponent } from './screens/lookup/lookup.component';
import { CancelComponent } from './screens/manage/cancel/cancel.component';
import { EditInfoComponent } from './screens/manage/edit-info/edit-info.component';
import { EditSpecComponent } from './screens/manage/edit-spec/edit-spec.component';
import { EtaScreenComponent } from './screens/manage/eta-screen/eta-screen.component';
import { PhotosComponent } from './screens/manage/photos/photos.component';
import { RescheduleComponent } from './screens/manage/reschedule/reschedule.component';
import { PaymentSuccessComponent } from './screens/payment-success/payment-success.component';
import { ReceiptComponent } from './screens/receipt/receipt.component';
import { SuccessComponent } from './screens/success/success.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'start/territory/1',
    pathMatch: 'full',
  },
  {
    path: 'start/:flowType/:id',
    component: SplitScreenPageComponent,
  },
  {
    path: 'spec/:flowType/:id',
    component: DonationDetailsComponent,
  },
  {
    path: 'place-time/:flowType/:id',
    component: PlaceTimeComponent,
  },
  {
    path: 'additional-information/:flowType/:id',
    component: AdditionalInformationComponent,
  },
  {
    path: 'confirm/:flowType/:id',
    component: PaymentComponent,
  },
  {
    path: 'start/:id',
    redirectTo: 'start/charity/:id',
  },
  {
    path: 'spec/:id',
    redirectTo: 'spec/charity/:id',
  },
  {
    path: 'place-time/:id',
    redirectTo: 'place-time/charity/:id',
  },
  {
    path: 'additional-information/:id',
    redirectTo: 'additional-information/charity/:id',
  },
  {
    path: 'confirm/:id',
    redirectTo: 'confirm/charity/:id',
  },
  {
    path: 'additional-information',
    redirectTo: 'additional-information/territory/1',
  },
  {
    path: 'place-time',
    redirectTo: 'place-time/territory/1',
  },
  {
    path: 'confirm',
    redirectTo: 'confirm/territory/1',
  },
  {
    path: 'card-on-file',
    component: PaymentComponent,
  },
  {
    path: 'receipt/:code',
    component: ReceiptComponent,
  },
  {
    path: 'payment-success/:code',
    component: PaymentSuccessComponent,
  },
  {
    path: 'fail',
    component: FailComponent,
  },
  {
    path: 'i',
    component: DonationsComponent,
  },
  {
    path: 'lookup',
    component: LookupComponent,
  },
  {
    path: 'd/:code',
    component: ConfirmComponent,
  },
  {
    path: 'i/:code',
    component: StateComponent,
  },
  {
    path: 'e/:code',
    component: EditComponent,
  },
  {
    path: 'i/:code/reschedule',
    component: RescheduleComponent,
  },
  {
    path: 'i/:code/cancel',
    component: CancelComponent,
  },
  {
    path: 'i/:code/info',
    component: EditInfoComponent,
  },
  {
    path: 'i/:code/specification',
    component: EditSpecComponent,
  },
  {
    path: 'i/:code/photos',
    component: PhotosComponent,
  },
  {
    path: 'i/:code/eta',
    component: EtaScreenComponent,
  },
  {
    path: 'i/:code/cancel/success',
    component: SuccessComponent,
    data: {
      message: 'was canceled successfully!',
    },
  },
  {
    path: 'i/:code/reschedule/success',
    component: SuccessComponent,
    data: {
      message: 'was rescheduled successfully!',
      hasLink: true,
    },
  },
  {
    path: 'created/:id',
    component: CreatedComponent,
  },
  {
    path: 'success/:code',
    component: CreatedComponent,
  },
  {
    path: 'hc',
    component: HealthCheckComponent,
  },
  {
    path: 'page-not-found',
    component: NotFoundComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
