import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ButtonActivity,
  ENVIRONMENT,
  Environment,
  ErrorCode,
  Lead,
  PageActivity,
  Pricing,
  Specification,
} from '@domains';
import { AppService } from '@donor/app.service';
import { InfoDialogComponent } from '@donor/components/info-dialog/info-dialog.component';
import { StripeComponent } from '@payment';
import { LeadsService, LocalStorageService } from '@rspl-api';
import {
  DesignService,
  DonationEstimateComponent,
  ResponsiveService,
} from '@rspl-ui';
import { take } from 'rxjs/operators';
import { BaseCreateComponent, FlowStep } from '../base-create.component';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent extends BaseCreateComponent {
  showErrors = false;
  showQuoteDetails = false;

  stripeKey;
  @ViewChild(DonationEstimateComponent) estimate!: DonationEstimateComponent;
  @ViewChild(StripeComponent) payment!: StripeComponent;

  paymentIntent?: {
    booking_fee: boolean;
    client_secret: string;
  };
  cardOnFile = false;
  expired = false;

  constructor(
    public override service: AppService,
    protected override router: Router,
    protected override route: ActivatedRoute,
    protected override dialog: MatDialog,
    protected override leadsService: LeadsService,
    protected override localStorage: LocalStorageService,
    protected override designService: DesignService,
    public override responsiveService: ResponsiveService,
    @Inject(ENVIRONMENT) protected override environment: Environment
  ) {
    super(
      service,
      route,
      router,
      dialog,
      leadsService,
      localStorage,
      designService,
      responsiveService,
      FlowStep.Payment,
      environment
    );
    if (this.route.snapshot.url.find((x) => x.path === 'card-on-file')) {
      this.cardOnFile = true;
    }
    this.stripeKey = environment.stripeKey;
  }

  public pricing?: Pricing;

  public userTerms = false;
  isLoaded = false;

  init(): void {
    this.charity = this.service.charity;
    this.pricing = this.lead?.pricing;
    if (this.charityId && !this.charity) {
      this.service.getCharity(this.charityId).subscribe((charity) => {
        this.charity = charity;
      });
    }

    this.service
      .createLeadActivity(this.lead?.id, PageActivity.PAYMENT_SETUP_PAGE)
      .subscribe();
    this.createPaymentSetup();
  }

  createPaymentSetup() {
    if (this.service.donation) {
      this.leadsService
        .createPaymentSetup(this.service.donation)
        .pipe(take(1))
        .subscribe({
          next: (paymentIntent) => {
            this.paymentIntent = paymentIntent;
          },
          error: (error) => {
            if (error?.error?.code === ErrorCode.lead_expired_error) {
              this.expired = true;
            }
          },
        });
    }
  }

  next(): void {
    this.isSubmitting = true;
    this.payment.onPayment();
  }

  saveSuccess() {
    this.service
      .createLeadActivity(this.lead?.id, ButtonActivity.PAYMENT_SETUP_SUBMIT)
      .subscribe(() => {
        this.router.navigate(['/', 'created', this.service.donation?.id]);
        this.service.reset();
      });
  }

  back() {
    this.router.navigate(
      ['/', 'additional-information', this.flowType, this.flowId],
      {
        queryParams: this.route.snapshot.queryParams,
      }
    );
  }

  get donationPrice(): number {
    return this.lead?.specification && this.pricing
      ? Pricing.getTotalPrice(this.lead.specification, this.pricing)
      : 0;
  }

  protected setError(err?: string) {
    this.service
      .createLeadActivity(
        this.lead?.id,
        ButtonActivity.PAYMENT_SETUP_SUBMIT_ERROR
      )
      .subscribe();
    this.isSubmitting = false;
    this.error = err;
  }

  cardReady() {
    this.isLoaded = true;
  }

  get valid(): boolean {
    if (new Specification(this.estimate.specification).isEmpty()) {
      this.dialog.open(InfoDialogComponent, {
        data: {
          title: 'No Items',
          message:
            'You need to select at least one item to donate before proceeding to the next step',
          button: 'I understand.',
          icon: 'warning',
        },
      });
      return false;
    }
    if (!this.userTerms) {
      this.service
        .createLeadActivity(
          this.lead?.id,
          ButtonActivity.PAYMENT_SETUP_SUBMIT_ERROR
        )
        .subscribe();
      return false;
    }
    return true;
  }

  showError(): void {
    this.showErrors = true;
    window.scrollTo(0, document.body.scrollHeight);
  }

  saveData(): ButtonActivity {
    if (this.estimate.isChanged)
      this.service.donation = new Lead({
        ...this.service.donation,
        specification: new Specification(this.estimate.specification),
        marketingSource: this.marketingSource,
      });
    return ButtonActivity.PAYMENT_SETUP_SUBMIT;
  }

  onCheckboxChange(event: any) {
    this.userTerms = !this.userTerms;
    this.service
      .createLeadActivity(
        this.lead?.id,
        ButtonActivity.PAYMENT_CHECKBOX,
        this.userTerms ? 'checked' : 'unchecked'
      )
      .subscribe();
  }

  get bookingFee(): number | null | undefined {
    return (
      this.pricing?.bookingFee || this.charity?.market?.pricing?.bookingFee
    );
  }

  disabledButtonsClicked() {
    if (!this.valid) {
      this.showError();
    }
  }

  onPaymentSetupSuccess(activity: ButtonActivity) {
    this.service.createLeadActivity(this.lead?.id, activity).subscribe();
  }
}
