<div
  class="eta-section"
  *ngIf="
    donation &&
    (isSameDay || donation.eta)
  "
>
  <div fxLayout="column" fxLayoutGap="15px">
    <div fxLayout="column">
      <span class="eta-label">Estimated Time of arrival (ETA):</span>
      <span class="input-feel">{{ donation.eta || '/' }}</span>
    </div>
    <div fxLayout="column">
      <span class="eta-label">ETA note:</span>
      <span class="input-feel">{{ donation.etaNote || '/' }}</span>
    </div>
    <button
      *ngIf="isSameDay"
      class="primary-page-btn eta-button block-btn"
      (click)="requestETA()"
      rsplDisableDoubleClick
      [disabled]="donation.requestEta || !!donation.meta?.etaRequestedAt"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="5px"
      [ladda]="isSubmitting"
    >
      <mat-icon *ngIf="donation.requestEta">timer</mat-icon>
      <span>{{
        donation.requestEta ? "Waiting for driver's ETA" : 'Request ETA'
      }}</span>
    </button>
  </div>
</div>
