<ng-container
  *ngIf="design"
  [ngTemplateOutlet]="DESIGN_2"
  ></ng-container>
<ng-template #DESIGN_2>
  <app-page-header
    title="Reschedule your donation."
    subtitle="Pick a new date and reschedule your donation pickup"
    [hasBack]="true"
    (onBack)="back()"
  ></app-page-header>
  <div class="page-content has-back" fxLayout="column">
    <div fxLayout="column" class="availability">
      <rspl-availability
        *ngIf="donation"
        [charityId]="donation.charity?.id || ''"
        (dateSelected)="onDateSelected($event)"
        [selectedDate]="selectedDate"
        [zip]="donation.address.zip"
        fxFlex="100"
        [showSelectedDate]="true"
        [disableDates]="[donation.date]"
      ></rspl-availability>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center">
      <button
        class="primary-btn warn-btn filled-btn"
        (click)="confirm()"
        [disabled]="
          !selectedDate ||
          (selectedDate.date === donation?.date &&
            selectedDate.partOfDay === donation?.partOfDay)
        "
        [ladda]="isSubmitting"
      >
        Confirm
      </button>
    </div>
  </div>
</ng-template>
