import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ButtonActivity,
  DONOR_CANCEL_REASONS,
  Donation,
  DonationPartnerState,
  PageActivity,
  Reason,
} from '@domains';
import { AppService } from '@donor/app.service';
import { DonationsService } from '@rspl-api';
import {
  ConfirmDialogComponent,
  DesignService,
  Designable,
  ResponsiveService,
} from '@rspl-ui';
import * as moment from 'moment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss'],
})
export class CancelComponent extends Designable implements OnInit {
  donationCode: string;
  donation?: Donation;
  
  reasons: Reason[];
  selectedReason: Reason;
  reason: FormControl<Reason>;
  subReason: FormControl<string>;
  additionalText: FormControl<string>;

  isSubmitting = false;
  reasonBase = '';
  queryParams: any;
  showErrors = false;
  errorStateMatcher = {
    isErrorState: (ctrl: FormControl<string>) => ctrl.invalid
  };

  constructor(
    private service: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private currency: CurrencyPipe,
    private donationsService: DonationsService,
    designService: DesignService,
    responsiveService: ResponsiveService
  ) {
    super(designService, responsiveService);
    this.donationCode = this.route.snapshot.params['code'];
    this.queryParams = this.route.snapshot.queryParams;
    
    this.reason = new FormControl<Reason>(null, Validators.required);
    this.subReason = new FormControl<string>(null, Validators.required);
    this.additionalText = new FormControl<string>(null, Validators.required);
    this.reasons = DONOR_CANCEL_REASONS;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.service
      .getDonationByCode(this.donationCode)
      .pipe(take(1))
      .subscribe({
        next: (donation) => {
          this.donation = donation;
          if (!this.service.canCancelDonation(this.donation)) {
            this.router.navigate([`/i/${this.donationCode}`]);
          }
          this.service
            .createDonationActivity(
              this.donation?.id,
              PageActivity.CANCEL_DONATION_PAGE
            )
            .subscribe();
          if (
            moment(donation.date).format('YYYY-MM-DD') ===
            moment().format('YYYY-MM-DD')
          ) {
            this.reasonBase = '[Same Day] ';
          } else {
            this.reasonBase = '[Prior Notice] ';
          }
        },
        error: () => {
          this.router.navigate(['/', 'page-not-found'], {queryParams: { message: `Donation <b>${this.donationCode}</b> could not be found!`, url: window.location.href }});
        },
      });
  }

  back() {
    if (Object.keys(this.queryParams).length) {
      this.router.navigate(['/', 'i', this.donationCode], {
        queryParams: this.queryParams,
      });
    } else {
      this.router.navigate(['/', 'i', this.donationCode]);
    }
  }

  onCancel() {
    this.reason.updateValueAndValidity();
    this.reason.markAsTouched();
    this.subReason.updateValueAndValidity();
    this.subReason.markAsTouched();
    this.additionalText.updateValueAndValidity();
    this.additionalText.markAsTouched();

    this.showErrors = this.reason.invalid || this.subReason.invalid || this.additionalText.invalid;
    if (this.showErrors) {
      return;
    }
    const cancelationFee =
      this.donation?.charity?.marketId &&
      this.donation?.pricing?.cancellationFee &&
      ![
        DonationPartnerState.assigned,
        DonationPartnerState.unassigned,
        DonationPartnerState.declined,
      ].includes(this.donation?.partnerState) &&
      this.donation.date === moment().format('YYYY-MM-DD')
        ? this.donation?.pricing?.cancellationFee
        : this.donation?.charity?.marketId
        ? null
        : 15.0;
    this.dialog
      .open(ConfirmDialogComponent, {
        width: '500px',
        data: {
          color: 'primary',
          title: 'Cancel Donation',
          yes: 'Yes',
          no: 'No',
          message:
            'Are you sure you want to cancel donation?' +
            (cancelationFee
              ? `<br/>${this.currency.transform(
                  cancelationFee
                )} cancellation fee will be charged`
              : ''),
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.cancel();
        }
      });
  }

  cancel() {
    this.service
      .createDonationActivity(
        this.donation?.id,
        ButtonActivity.CANCEL_DONATION_SUBMIT
      )
      .subscribe();
    this.isSubmitting = true;
    let reason = this.reasonBase + this.reason.value.reason + ' : ' + this.subReason.value;
    let note = this.additionalText.value;
    this.donationsService
      .updateDonationStateByCode(this.donationCode, {
        stateAction: 'cancel',
        terminationReason: reason,
        terminationNote: note
      })
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.router.navigate([`/i/${this.donationCode}/cancel/success`]);
        },
        error: () => {
          this.isSubmitting = false;
          this.service
            .createDonationActivity(
              this.donation?.id,
              ButtonActivity.CANCEL_DONATION_SUBMIT_ERROR
            )
            .subscribe();
        },
      });
  }

  setSelectedReason(): void {
    this.selectedReason = this.reason.value;
    if (this.selectedReason.children) {
      this.subReason.setValidators(Validators.required);
      this.additionalText.clearValidators();
      this.subReason.updateValueAndValidity();
      this.additionalText.updateValueAndValidity();
    } else {
      this.subReason.clearValidators();
      this.additionalText.setValidators(Validators.required);
      this.additionalText.updateValueAndValidity();
    }
  }
}
