import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { Designable, DesignService, ResponsiveService } from '@rspl-ui';

export interface InfoDialogData {
  title: string;
  icon?: string;
  subtitle?: string;
  button?: string;
  color: string;
  message: string;
}

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent extends Designable {

  public constructor(
    public reference: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InfoDialogData,
    protected override designService: DesignService,
    public override responsiveService: ResponsiveService
  ) {
    super(designService, responsiveService);
  }

  public onChoice(choice: boolean): void {
    this.reference.close(choice);
  }

}
