import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ButtonActivity,
  Charity,
  ENVIRONMENT,
  Environment,
  Lead,
  PageActivity,
  Pricing,
  Specification,
} from '@domains';
import { AppService } from '@donor/app.service';
import { CategorizeHelpDialogComponent } from '@donor/components/categorize-help-dialog/categorize-help-dialog.component';
import { InfoDialogComponent } from '@donor/components/info-dialog/info-dialog.component';
import {
  BaseCreateComponent,
  FlowStep,
  FlowTypes,
} from '@donor/screens/create/base-create.component';
import { DonationsService, LeadsService, LocalStorageService } from '@rspl-api';
import { DesignService, ResponsiveService } from '@rspl-ui';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-donation-details',
  templateUrl: './donation-details.component.html',
  styleUrls: ['./donation-details.component.scss'],
})
export class DonationDetailsComponent extends BaseCreateComponent {
  public specification?: Specification = new Specification();
  public totalCostEstimate?: number;
  public fuelFee?: number;
  public pricing: Pricing =
    this.service.charity?.market?.pricing ||
    this.service.pricing ||
    new Pricing();

  loaded = false;

  constructor(
    public override service: AppService,
    protected override route: ActivatedRoute,
    protected override router: Router,
    protected override dialog: MatDialog,
    private http: HttpClient,
    private donationsService: DonationsService,
    protected override leadsService: LeadsService,
    protected override localStorage: LocalStorageService,
    protected override designService: DesignService,
    public override responsiveService: ResponsiveService,
    @Inject(ENVIRONMENT) protected override environment: Environment
  ) {
    super(
      service,
      route,
      router,
      dialog,
      leadsService,
      localStorage,
      designService,
      responsiveService,
      FlowStep.DonationDetails,
      environment
    );
  }

  init(): void {
    this.specification = this.lead?.specification;
    if (this.specification?.isEmpty()) {
      if (this.route.snapshot.queryParams['disassembly'] === 'true') {
        this.specification.disassembly = 1;
      } else {
        this.specification.disassembly = 0;
      }
      if (this.route.snapshot.queryParams['stairs'] === 'true') {
        this.specification.staircases = 1;
      } else {
        this.specification.staircases = 0;
      }
    }
    if (this.flowType !== FlowTypes.CHARITY) {
      this.getDefaultPricing();
      if (this.service.donation) {
        this.setDonation();
        this.loaded = true;
      }
      this.loaded = true;
    }
    this.service
      .createLeadActivity(this.lead?.id, PageActivity.DONATION_SPEC_PAGE)
      .pipe(take(1))
      .subscribe();
    this.onSpecificationChange(this.specification);
  }

  public override getCharity(id: string): void {
    if (this.service.charity) {
      this.setCharity(this.service.charity);
      if (this.service.donation) {
        this.setDonation();
        this.loaded = true;
      }
    } else {
      this.service.getCharity(id).subscribe({
        next: (charity) => {
          this.setCharity(charity);
          this.loaded = true;
        },
        error: () => {
          this.router.navigate(['/', 'page-not-found'], {
            queryParams: {
              message: `Charity #${id} could not be found!`,
              url: window.location.href,
            },
          });
        },
      });
    }
  }

  private setCharity(charity: Charity) {
    this.charity = charity;
    this.service.charity = charity;
    if (!this.service.pricing) {
      this.service.pricing = charity.market?.pricing;
    }
    if (this.service.pricing) {
      this.pricing = this.service.pricing;
      this.getTotalPrice();
    } else {
      this.getDefaultPricing();
    }
  }

  isEmptySpec(specification?: Specification): boolean {
    return (
      !specification ||
      !(
        specification.xlarge > 0 ||
        specification.large > 0 ||
        specification.medium > 0 ||
        (!this.charity?.meta?.onlySmallItems && specification.small > 0) ||
        (this.charity?.meta?.onlySmallItems && specification.small > 5)
      )
    );
  }

  onSpecificationChange(e: any): void {
    Object.assign(this.specification || {}, e);
    this.getTotalPrice();
  }

  public getTotalPrice(): void {
    if (this.specification && this.pricing?.base) {
      this.fuelFee = this.pricing.fuelFee
        ? (Pricing.getTotalBasePrice(this.specification, this.pricing) *
            this.pricing.fuelFee) /
          100
        : 0;

      this.totalCostEstimate = Pricing.formatPrice(
        Pricing.getTotalBasePrice(this.specification, this.pricing) +
          this.fuelFee
      );
    }
  }

  getDefaultPricing(): void {
    this.donationsService.getDefaultPricing().subscribe((pricing) => {
      this.pricing = pricing;
      this.service.pricing = this.pricing;
      this.getTotalPrice();
    });
  }

  saveData(): ButtonActivity {
    this.service.donation = new Lead({
      ...this.service.donation,
      specification: new Specification(this.specification),
      marketingSource: this.marketingSource,
    });
    return ButtonActivity.DONATION_SPEC_SUBMIT;
  }

  next(): void {
    this.router.navigate(['/', 'place-time', this.flowType, this.flowId], {
      queryParams: this.route.snapshot.queryParams,
    });
  }

  back() {
    if (this.hasBack) {
      this.router.navigate(['/', 'start', this.flowType, this.flowId], {
        queryParams: this.route.snapshot.queryParams,
      });
    }
  }

  showError() {
    this.service
      .createLeadActivity(
        this.lead?.id,
        ButtonActivity.DONATION_SPEC_SUBMIT_ERROR
      )
      .pipe(take(1))
      .subscribe();
    this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'No Items',
        message:
          'You need to select at least one item to donate before proceeding to the next step',
        button: 'I understand.',
        icon: 'warning',
      },
    });
  }

  get valid(): boolean {
    return (
      (this.specification?.xlarge || 0) > 0 ||
      (this.specification?.large || 0) > 0 ||
      (this.specification?.medium || 0) > 0 ||
      (this.specification?.small || 0) > 0
    );
  }

  private setDonation() {
    this.specification = this.service.donation?.specification;
    this.marketingSource =
      this.service.donation?.marketingSource || this.marketingSource;
    this.getTotalPrice();
  }

  categorizeHelp() {
    this.dialog.open(CategorizeHelpDialogComponent, {
      ...(this.isSmallWindowSize
        ? {
            width: '900px',
            maxWidth: '90vw',
            height: '85vh',
            maxHeight: '85vh',
          }
        : {}),
      ...{
        panelClass: 'categorize-help-dialog',
        data: this.charity,
      },
    });
  }

  get hasBack(): boolean {
    return (
      (this.flowType === FlowTypes.CHARITY &&
        !!this.charity?.meta?.splitScreenConfig) ||
      (this.flowType === FlowTypes.TERRITORY &&
        this.territoriesWithLanding.includes(this.flowId))
    );
  }
}
