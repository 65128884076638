<ng-container
  *ngIf="design"
  [ngTemplateOutlet]="DESIGN_2"
></ng-container>
<ng-template #DESIGN_2>
  <app-page-header
    [title]="charity?.meta?.onlySmallItems ? 'Choose the number of bags or boxes for pickup.' : 'Choose the items you\'ll be donating.'"
    [subtitle]="charity?.meta?.onlySmallItems ? 'Up to 5 bags or boxes included for ' + (loaded ? ( (pricing.base + pricing.small * 5) | currency) : '') + ' fee.' : 'If you need help categorizing your items, click on the button below.'"
    [hasBack]="hasBack"
    (onBack)="back()"
  >
    <button class="primary-btn filled-btn" (click)="categorizeHelp()">
      Help Me Categorize
    </button>
  </app-page-header>
  <div
    class="page-content donation-details"
    fxLayout="column"
    *ngIf="loaded"
  >
    <div
      class="donation-info step-content"
      fxLayoutAlign="center center"
      fxLayoutAlign.xs="center start"
      fxFlex
    >
      <div fxLayout="column" fxLayoutAlign="center center" *ngIf="!!lead">
        <app-donation-spec
          [charityId]="charityId"
          [specification]="specification"
          [totalCostEstimate]="totalCostEstimate"
          [pricing]="pricing"
          (onSpecificationChange)="onSpecificationChange($event)"
          (onSave)="save()"
          [leadId]="lead.id"
          [charity]="charity"
          [smallLabel]="charity?.meta?.onlySmallItems ? 'If more than 5 bags or boxes (eg. for 6 add 1 above)' : '1-person lift'"
          [showPrice]="false"
          [showElevator]="false"
        ></app-donation-spec>
      </div>
    </div>
    <div fxLayout="column" fxLayout.gt-sm="row" class="progress-wrapper">
      <app-create-progress fxFlex [step]="0"></app-create-progress>
    </div>
  </div>
</ng-template>
