import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Designable, DesignService, ResponsiveService } from '@rspl-ui';

@Component({
  selector: 'app-create-progress',
  templateUrl: './create-progress.component.html',
  styleUrls: ['./create-progress.component.scss'],
})
export class CreateProgressComponent extends Designable implements OnInit {
  @Input() step: 0 | 1 | 2 | 3 = 0;

  id?: string;
  flowType?: string;

  steps = [
    { percent: 0, name: 'Items', link: 'spec' },
    { percent: 1, name: 'Schedule', link: 'place-time' },
    { percent: 2, name: 'Information', link: 'additional-information' },
    { percent: 3, name: 'Payment', link: 'confirm' },
  ];

  constructor(
    protected override designService: DesignService,
    public override responsiveService: ResponsiveService,
    protected route: ActivatedRoute
  ) {
    super(designService, responsiveService);
    this.id = this.route.snapshot.params['id'];
    this.flowType = this.route.snapshot.params['flowType'];
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  getRoute(step: { percent: number, name: string, link: string },) {
    return ['/', step.link, this.flowType, this.id];
  }
}
