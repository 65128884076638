import { Injectable } from '@angular/core';
import {
  ButtonActivity,
  Charity,
  Design,
  Donation,
  DonationDonorState,
  DonationPartnerState,
  InputActivity,
  Lead,
  Organization,
  PageActivity,
  PartOfDay,
  Pricing,
} from '@domains';
import {
  CharityService,
  DonationsService,
  LeadsService,
  OrganizationsService,
} from '@rspl-api';
import { DesignService } from '@rspl-ui';
import { CalendarOptions, ICalendar } from 'datebook';
import * as moment from 'moment';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { StripeService } from 'ngx-stripe';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public donation?: Donation | Lead | any;
  public charity?: Charity | null;
  public territory?: Organization | null;
  public pricing?: Pricing;
  public zipCode?: string | null;
  public showCharityLogo = true;
  private charitySubject = new BehaviorSubject<Charity | undefined | null>(undefined);
  public charity$ = this.charitySubject.asObservable();
  private territorySubject = new BehaviorSubject<Organization | undefined | null>(undefined);
  public territory$ = this.territorySubject.asObservable();
  manualUrlParam = false;

  constructor(
    private donationsService: DonationsService,
    private leadsService: LeadsService,
    private charityService: CharityService,
    private organizationService: OrganizationsService,
    private stripeService: StripeService,
    private designService: DesignService,
    private gaService: GoogleAnalyticsService
  ) {}

  reset() {
    this.donation = undefined;
    this.zipCode = undefined;
    this.pricing = undefined;
    this.charity = undefined;
    this.charitySubject.next(this.charity);
    this.territorySubject.next(this.territory);
  }

  getCharity(id: string): Observable<Charity> {
    return this.charityService.find(id, true, ['market', 'screening']).pipe(
      tap((charity: Charity) => {
        this.charity = charity;
        this.charitySubject.next(this.charity);
      })
    );
  }

  getTerritory(id: string): Observable<Organization> {
    return this.organizationService.find(id).pipe(
      tap((territory: Organization) => {
        this.territory = territory;
        this.territorySubject.next(this.territory);
      })
    );
  }

  createDonation(donation: Donation): Observable<Donation> {
    return this.donationsService.create(donation).pipe(
      tap((result: Donation) => {
        this.charity = result.charity;
        this.charitySubject.next(this.charity);
      })
    );
  }

  saveLead(lead?: Lead): Observable<Lead> {
    const req = lead?.id
      ? this.leadsService.update(lead?.id, lead)
      : this.leadsService.create(lead || {});
    return req.pipe(
      tap((result: Lead) => {
        this.donation = result;
        this.zipCode = this.donation.address?.zip;
      })
    );
  }

  assignCharityToLead(leadId: string): Observable<Lead> {
    return this.leadsService.assignCharity(leadId).pipe(
      switchMap(() => this.getLead(leadId))
    );
  }

  getLead(id: string): Observable<Lead> {
    return this.leadsService.find(id).pipe(
      tap((result: Lead) => {
        this.donation = result;
        this.zipCode = this.donation.address?.zip;
      })
    );
  }

  submitDonation(leadId: string): Observable<Donation> {
    return this.donationsService.convertLeadToDonation(leadId).pipe(
      tap((result: Donation) => {
        this.charity = result.charity;
        this.charitySubject.next(this.charity);
      })
    );
  }

  getDonationByCode(code: string): Observable<any> {
    return this.donationsService.getDonationByCode(code).pipe(
      tap((d) => {
        this.donation = d;
        this.charity = this.donation?.charity;
        this.charitySubject.next(this.charity);
        if (!d.charity) {
          this.designService.setDesign(Design.DESIGN_2);
        }
      })
    );
  }

  updateDonationByCode(donation: Donation): Observable<Donation> {
    return this.donationsService.updateDonationByCode(donation).pipe(
      tap((d) => {
        this.donation = d;
        this.charity = this.donation?.charity;
        this.charitySubject.next(this.charity);
      })
    );
  }

  addToCalendar(donation: Donation) {
    const start = moment(donation.date);
    const end = moment(donation.date);
    if (donation.partOfDay === PartOfDay.am) {
      start.hours(8).minutes(0).seconds(0).milliseconds(0);
      end.hours(12).minutes(0).seconds(0).milliseconds(0);
    } else {
      start.hours(12).minutes(0).seconds(0).milliseconds(0);
      end.hours(17).minutes(0).seconds(0).milliseconds(0);
    }
    const config: CalendarOptions = {
      title: 'Donation: ' + donation.donationCode,
      location:
        donation?.address?.street +
        ', ' +
        donation?.address?.city +
        ', ' +
        donation?.address?.state +
        ', ' +
        donation?.address?.zip,
      description: window.location.origin + '/i/' + donation.donationCode,
      start: start.toDate(),
      end: end.toDate(),
    };
    const icalendar = new ICalendar(config);
    icalendar.download();
  }

  canEditDonation(donation?: Donation): boolean {
    return (
      !!donation && donation.partnerState !== DonationPartnerState.completed
    );
  }

  canCancelDonation(donation?: Donation): boolean {
    return (
      !!donation &&
      donation.partnerState !== DonationPartnerState.completed &&
      donation.donorState !== DonationDonorState.canceled &&
      !(donation.payment?.authCompleted || donation.payment?.completed)
    );
  }

  getDonationStep(donation: Donation) {
    if (
      donation.donorState &&
      [DonationDonorState.submitted, DonationDonorState.rescheduled].includes(
        donation.donorState
      ) &&
      [
        DonationPartnerState.unassigned,
        DonationPartnerState.assigned,
        DonationPartnerState.declined,
      ].includes(donation.partnerState)
    ) {
      return 0;
    } else if (
      donation.donorState &&
      [
        DonationDonorState.submitted,
        DonationDonorState.rescheduled,
        DonationDonorState.confirmed,
      ].includes(donation.donorState) &&
      [DonationPartnerState.accepted].includes(donation.partnerState)
    ) {
      return 1;
    } else if (
      donation.donorState &&
      [
        DonationDonorState.submitted,
        DonationDonorState.rescheduled,
        DonationDonorState.confirmed,
      ].includes(donation.donorState) &&
      [DonationPartnerState.en_routed, DonationPartnerState.arrived].includes(
        donation.partnerState
      )
    ) {
      return 2;
    } else if (
      donation.partnerState === DonationPartnerState.quote_sent &&
      !donation.payment?.authCompleted
    ) {
      return 3;
    } else if (donation.payment?.authCompleted || donation.partnerState === DonationPartnerState.payment_skipped) {
      return 4;
    } else {
      return 5;
    }
  }

  createLeadActivity(
    leadId: string | undefined | null,
    type: PageActivity | ButtonActivity | InputActivity,
    value?: any
  ) {

    if(this.donation?.utm?.source === 'adwords' && Object.values(PageActivity).includes(type as PageActivity)) {
      let eventName = 'ADWORDS - ' + type;
      this.gaService.event(eventName, 'Adwords Campaign Event', eventName, value);
    }
    return this.leadsService.createLeadActivity(
      leadId,
      type,
      value,
      this.isManual ? 'm-' : ''
    );
  }

  createDonationActivity(
    donationId: string | undefined | null,
    type: PageActivity | ButtonActivity | InputActivity,
    value?: any,
    origin?: string
  ) {
    return this.donationsService.createDonationActivity(
      donationId,
      type,
      value,
      this.isManual ? 'm-' : '',
      origin
    );
  }


  get isManual(): boolean {
    return (
      this.donation?.marketingSource?.includes('manual') || this.manualUrlParam
    );
  }
}
