<ng-container
  *ngIf="design"
  [ngTemplateOutlet]="DESIGN_2"
></ng-container>
<ng-template #DESIGN_2>
  <app-page-header
    title="Choose the items you'll be donating."
    [hasBack]="true"
    (onBack)="back()"
  ></app-page-header>
  <div class="page-content has-back" fxLayout="column">
    <div
      class="donation-info step-content"
      fxLayoutAlign="center center"
      fxLayoutAlign.xs="center start"
    >
      <div fxLayout="column" fxLayoutAlign="center center">
        <app-donation-spec
          *ngIf="donation && donation.pricing"
          [charityId]="donation.charity?.id || ''"
          [specification]="specification"
          [totalCostEstimate]="totalCostEstimate"
          [pricing]="donation.pricing"
          (onSpecificationChange)="onSpecificationChange($event)"
          [showFee]="true"
          saveLabel="Confirm"
          (onSave)="onConfirm()"
        >
        </app-donation-spec>
      </div>
    </div>
  </div>
</ng-template>
