<div
  mat-dialog-content
  fxLayout="column"
  fxLayoutGap.lt-md="60px"
  fxLayoutGap="40px"
  fxFlexFill
>
  <div
    fxLayout.lt-md="column"
    fxLayout.gt-sm="row"
    fxFlex="100"
    fxLayoutGap.gt-sm="30px"
  >
    <div fxLayout="column" fxFlex.gt-sm="50" *ngIf="!data?.meta?.onlySmallItems">
      <div fxLayout="column">
        <h2>Extra Large Item</h2>
        <p>
          These are items requiring 2 or more people to lift and/or special
          equipment to move.
        </p>
        <p>
          Examples of Extra-Large Items are: Major Appliances, Entertainment
          Centers, China Cabinets or Similar, Wardrobes, Dining Room Tables (8
          seats or more), Executive desk, Recliner Couches (with electric
          motor), or similar items requiring at least 2 people to lift and/or
          special equipment to move.
        </p>
      </div>
      <div fxLayout="column">
        <h2>Large Item</h2>
        <p>
          These are items requiring 2 people to lift but not requiring special
          equipment to move.
        </p>
        <p>
          Examples: Standard six dining table (6 or less seats), Dressers,
          sideboard (buffet), standard desk, a couch/loveseat, Rugs (larger than
          5x7), chair (with motor/electric, recliner),
          <b>Sectionals by piece(each piece counts separately)</b>, or items of
          a similar nature.
        </p>
      </div>
      <div fxLayout="column">
        <h2>Medium Item</h2>
        <p>1-person lift</p>
        <p>
          Examples: Dining room or desk chairs, end tables, stools, ottoman,
          Rugs (5x7 or smaller), Wardrobe boxes, or similar items which can be
          carried by 1 person.
        </p>
      </div>
    </div>
    <div
      fxLayout="column"
      [fxFlex.gt-sm]="data?.meta?.onlySmallItems ? '100' : '50'"
    >
      <div fxLayout="column">
        <h2>Bag or Box</h2>
        <p>1-person lift</p>
        <p>
          Must be below 20lbs. Examples: Bags and boxes capable of being carried by one person and
          must be sealed.
        </p>
      </div>
      <div fxLayout="column">
        <h2>Staircases</h2>
        <p>If no elevator</p>
        <p>
          If your donations must be moved up or down stairs and no elevator is
          available, please select the number of staircases that must be
          traversed. Certain elevators may incur a separate fee.
        </p>
      </div>
      <div fxLayout="column" *ngIf="!data?.meta?.onlySmallItems">
        <h2>Disassembly</h2>
        <p>Requiring tooling/upcharge items</p>
        <p>
          Please add this for each item that requires tools for disassembly in
          order to remove!
        </p>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <button
      class="primary-btn filled-btn"
      fxFlex="100"
      (click)="reference.close()"
      cdkFocusInitial
    >
      I Understand
    </button>
  </div>
</div>
