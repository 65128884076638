<div class="report-wrapper" id="pdf">
  <div *ngIf="donation" class="report">
    <div class="rspl-logo">
      <mat-icon svgIcon="rspl-new-logo"></mat-icon>
      <span>Donation Pickup Service</span>
    </div>
    <div class="partner" *ngIf="donation.payment?.authCompleted && !donation.payment?.completed">
      <p>Receipt is in DRAFT state until Donation is delivered to {{donation.charity?.name}}</p>
    </div>
    <div *ngIf="donation && !donation.payment?.authCompleted">
      <p>Receipt is not ready!</p>
      <p>Payment is not authorized yet!</p>
    </div>
    <div *ngIf="donation.payment?.authCompleted" class="buttons" fxLayout="row" fxLayoutAlign="center center">
      <button
        class="primary-btn filled-btn"
        printSectionId="pdf"
        ngxPrint
        [useExistingCss]="true"
        printTitle="Receipt {{ donation.donationCode }}"
      >
        Download PDF receipt
      </button>
      <a
      class="primary-btn filled-btn"
        [href]="taxReceiptUrl + donation.donationCode" target="_blank"
      >
        Tax receipt
      </a>
    </div>
    <div class="total" *ngIf="donation.payment?.authCompleted">
      <p>Total Payment</p>
      <p>{{ donation.total | currency }}</p>
    </div>
    <div class="card-wrapper" *ngIf="donation.payment?.authCompleted">
      <div>
        <rspl-donation-estimate
          #estimate
          *ngIf="donation && donation.adjustedSpecification"
          [pricing]="donation.pricing"
          [gratuity]="donation.gratuity"
          [specification]="donation.adjustedSpecification"
          totalLabel="Total Cost"
          [bookingFee]="donation.pricing.bookingFee"
          [includeBookingInTotal]="true"
          showBookingFee="regular"
        ></rspl-donation-estimate>
      </div>
    </div>
    <div class="footer">
      <div>
        <img [src]="donation.donorSignature" />
        <p>{{ donation.donor.name }}</p>
      </div>
      <span>{{ donation.date | date: "MMM d, y" }}</span>
      <span>{{ donation.donationCode }}</span>
    </div>
  </div>
</div>
