import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Address,
  ButtonActivity,
  DonationMeta,
  ENVIRONMENT,
  Environment,
  InputActivity,
  Lead,
  PageActivity,
  PartOfDay,
  Pricing,
} from '@domains';
import { AppService } from '@donor/app.service';
import { LeadsService, LocalStorageService } from '@rspl-api';
import {
  AvailabilityComponent,
  DesignService,
  ResponsiveService,
} from '@rspl-ui';
import { take } from 'rxjs/operators';
import { BaseCreateComponent, FlowStep } from '../base-create.component';

@Component({
  selector: 'app-place-time',
  templateUrl: './place-time.component.html',
  styleUrls: ['./place-time.component.scss'],
})
export class PlaceTimeComponent extends BaseCreateComponent {
  #availability!: AvailabilityComponent;
  @ViewChild(AvailabilityComponent) set availability(
    availability: AvailabilityComponent
  ) {
    const shouldSetZip = !this.#availability;
    this.#availability = availability;
    if (
      shouldSetZip &&
      this.address?.zip &&
      this.address.zip !== this.availability.zip
    ) {
      this.zip = this.address.zip;
    }
  }

  get availability(): AvailabilityComponent {
    return this.#availability;
  }

  zip?: string | null;

  totalCostEstimate = 99.99;
  pricing?: Pricing;
  selectedDate?: {
    date?: string;
    partOfDay?: PartOfDay;
  };
  zipChangeTimeout: any;
  form!: FormGroup<{
    zipCode: FormControl<string | null | undefined>;
  }>;
  zipFetched = false;
  address?: Address;
  partsOfDay = PartOfDay;
  manualAddress = false;
  smallItemPickupCharityPartners;

  constructor(
    public override service: AppService,
    protected override router: Router,
    protected override route: ActivatedRoute,
    protected override dialog: MatDialog,
    protected override leadsService: LeadsService,
    protected override localStorage: LocalStorageService,
    protected override designService: DesignService,
    public override responsiveService: ResponsiveService,
    @Inject(ENVIRONMENT) protected override environment: Environment
  ) {
    super(
      service,
      route,
      router,
      dialog,
      leadsService,
      localStorage,
      designService,
      responsiveService,
      FlowStep.PlaceTime,
      environment
    );
    
    this.zip = this.service.zipCode;
    this.smallItemPickupCharityPartners =
      environment.special.smallItemPickupCharityPartners;
  }

  init(): void {
    this.service
      .createLeadActivity(this.lead?.id, PageActivity.AVAILABILITY_PAGE)
      .subscribe();

    this.selectedDate = {
      ...this.selectedDate,
      date: this.service.donation?.date,
      partOfDay: this.service.donation?.partOfDay as PartOfDay,
    };
    this.address = this.service.donation?.address;
    this.form = new FormGroup({
      zipCode: new FormControl<string | null | undefined>(
        this.service.donation?.address?.zip || this.service.zipCode,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
          Validators.pattern(/^\d{5}$/),
        ]
      ),
    });
    if (this.service.zipCode) {
      this.zipChanged();
    }
  }

  onDateSelected(e: { date?: string; partOfDay?: PartOfDay }): void {
    if (
      e.date &&
      e.partOfDay &&
      e.date !== this.selectedDate?.date &&
      e.partOfDay !== this.selectedDate?.partOfDay
    ) {
      this.service
        .createLeadActivity(
          this.lead?.id,
          InputActivity.DATE_SELECTED,
          `${e.date} ${e.partOfDay}`
        )
        .subscribe();
    }
    this.selectedDate = e;
    this.errorVisible =
      this.dirty && (!this.selectedDate.date || !this.selectedDate.partOfDay);
  }

  saveData(): ButtonActivity {
    this.service.donation = new Lead({
      ...this.service.donation,
      date: this.selectedDate?.date,
      partOfDay: this.selectedDate?.partOfDay,
      address: new Address({
        ...this.address,
      }),
      meta: new DonationMeta({
        ...this.service.donation.meta,
        manualAddress: this.manualAddress,
      }),
    } as Lead);
    this.service.zipCode = this.zipCode.value;
    return ButtonActivity.AVAILABILITY_SUBMIT;
  }

  next(): void {
    if (this.lead?.id) {
      this.service.assignCharityToLead(this.lead?.id).pipe(take(1)).subscribe();
      this.router.navigate(
        ['/', 'additional-information', this.flowType, this.flowId],
        {
          queryParams: this.route.snapshot.queryParams,
        }
      );
    }
  }

  back() {
    if (this.service.donation && this.form) {
      Object.assign(this.service.donation, this.form.getRawValue());
    }
    this.router.navigate(['/', 'spec', this.flowType, this.flowId], {
      queryParams: this.route.snapshot.queryParams,
    });
  }

  zipChanged() {
    const zip = this.zipCode?.value?.replace(/[^0-9]/g, '')?.slice(0, 5);
    this.zipCode.patchValue(zip);
    if (zip?.length === 5 || zip?.length === 0) {
      if (this.zipChangeTimeout) {
        clearTimeout(this.zipChangeTimeout);
      }
      this.zipChangeTimeout = setTimeout(() => {
        this.zip = zip;
        this.zipFetched = true;
      }, 300);
    }
  }

  get zipCode(): FormControl<string | null | undefined> {
    return this.form?.get('zipCode') as FormControl<string | null | undefined>;
  }

  onAddressChanged(address?: Address, manual?: boolean): void {
    this.address = new Address({
      ...address,
    });
    this.manualAddress = !!manual;
    if (this.zipCode.value !== address?.zip) {
      this.selectedDate = undefined;
      this.zipCode.patchValue(address?.zip);
      this.zipChanged();
    }
    this.service
      .createLeadActivity(
        this.leadId,
        this.form.valid
          ? InputActivity.ADDRESS_ENTER
          : InputActivity.ADDRESS_ENTER_ERROR,
        this.address.fullAddress
      )
      .subscribe();
    if (this.manualAddress) {
      this.service
        .createLeadActivity(
          this.leadId,
          InputActivity.ADDRESS_MANUAL,
          this.address.fullAddress
        )
        .subscribe();
    }
  }

  onAddressError(address?: string): void {
    this.service
      .createLeadActivity(
        this.leadId,
        InputActivity.ADDRESS_ENTER_ERROR,
        address
      )
      .subscribe();
  }

  onAddressPopupOpened(): void {
    this.service
      .createLeadActivity(this.leadId, ButtonActivity.ADDRESS_ATTEMPT)
      .subscribe();
  }

  showError(): void {
    this.service
      .createLeadActivity(
        this.lead?.id,
        ButtonActivity.AVAILABILITY_SUBMIT_ERROR
      )
      .pipe(take(1))
      .subscribe();
  }

  get valid(): boolean {
    return (
      !!this.selectedDate &&
      !!this.selectedDate.date &&
      !!this.selectedDate.partOfDay &&
      this.form.valid
    );
  }

  get fullAddress(): string {
    return (
      this.address?.street +
      ', ' +
      this.address?.city +
      ', ' +
      this.address?.state +
      ', ' +
      this.address?.zip +
      ((this.address?.secondary?.length || 0) > 0
        ? ' #' + this.address?.secondary
        : '')
    );
  }
}
