<div class="page-header big-header" fxLayoutAlign="start end">
  <h2 [innerHTML]="title"></h2>
</div>
<div class="page-content">
  <div class="outcome-wrapper" fxLayout="column" fxLayoutAlign="center start">
    <p [innerHTML]="message"></p>
    <a
      class="primary-page-btn gray-btn"
      ngClass.lt-md="block-btn"
      *ngIf="hasLink"
      mat-raised-button
      [routerLink]="['/', 'i', donationCode]"
    >
      Manage and Track Your Donation
    </a>
  </div>
  <mat-icon svgIcon="rspl-small-logo"></mat-icon>
</div>
