import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@donor/app.service';
import { DonationsService } from '@rspl-api';
import { ButtonActivity, Donation, PageActivity } from '@domains';
import { PhotoUploadComponent, Designable, DesignService, ResponsiveService } from '@rspl-ui';
import { finalize, take } from 'rxjs/operators';

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent extends Designable implements OnInit {
  @ViewChild(PhotoUploadComponent) fileUpload!: PhotoUploadComponent;
  donationCode: string;
  donation?: Donation;
  isSubmitting = false;
  queryParams;
  photos = ['https://staging-resupply-uploads.s3.amazonaws.com/uploads/public/logologo.png', 'https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?cs=srgb&dl=pexels-anjana-c-674010.jpg&fm=jpg']
  step = 5;

  constructor(
    private service: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private donationsService: DonationsService,
    designService: DesignService,
    responsiveService: ResponsiveService
  ) {
    super(designService, responsiveService);
    this.donationCode = this.route.snapshot.params['code'];
    this.queryParams = this.route.snapshot.queryParams;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.service
      .getDonationByCode(this.donationCode)
      .pipe(take(1))
      .subscribe({
        next: (donation) => {
          this.donation = donation;
          if (!this.service.canEditDonation(this.donation)) {
            this.router.navigate([`/i/${this.donationCode}`]);
          } else if (this.donation) {
            this.service.createDonationActivity(this.donation?.id, PageActivity.DONATION_PHOTOS_PAGE).subscribe();
            this.step = this.service.getDonationStep(this.donation);
          }
        },
        error: () => {
          this.router.navigate(['/', 'page-not-found'], {queryParams: { message: `Donation <b>${this.donationCode}</b> could not be found!`, url: window.location.href }});
        }
      });
  }

  back() {
    if (Object.keys(this.queryParams).length) {
      this.router.navigate([`/i`], {
        queryParams: this.queryParams,
      });
    } else {
      this.router.navigate([`/i/${this.donationCode}`]);
    }
  }

  onConfirm() {
    if (!this.donation) return;
    this.service.createDonationActivity(this.donation?.id, ButtonActivity.ADD_DONATION_PHOTOS_SUBMIT).subscribe();
    this.isSubmitting = true;
    this.service
      .updateDonationByCode(new Donation({...this.donation}))
      .pipe(
        finalize(() => (this.isSubmitting = false)),
        take(1)
      )
      .subscribe({
        next: (donation) => {
          this.donation = donation;
          this.snackBar.open('Your information has been updated successfully!', 'x', {
            duration: 5000,
            panelClass: 'success'
          });
          this.back();
        },
        error: () => {
          this.service.createDonationActivity(this.donation?.id, ButtonActivity.ADD_DONATION_PHOTOS_SUBMIT_ERROR).subscribe();
        }
      });
  }

  remove(i: number) {
    if (!this.donation?.photos || !this.donation?.photos[i]) {
      return;
    }
    this.donation.photos.splice(i, 1);
  }

  add(image: string) {
    if (!this.donation) {
      return;
    }
    if (!this.donation.photos) {
      this.donation.photos = [];
    }
    this.donation.photos.splice(0, 0, image);
    this.fileUpload.reset();
  }
}
