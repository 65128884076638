import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ButtonActivity,
  Charity,
  CharityMeta,
  ENVIRONMENT,
  Environment,
} from '@domains';
import { AppService } from '@donor/app.service';
import { DonationsService, LeadsService, LocalStorageService } from '@rspl-api';
import { DesignService, ResponsiveService } from '@rspl-ui';
import { catchError, of, take } from 'rxjs';
import {
  BaseCreateComponent,
  FlowStep,
  FlowTypes,
} from '../base-create.component';

@Component({
  selector: 'app-split-screen-page',
  templateUrl: './split-screen-page.component.html',
  styleUrls: ['./split-screen-page.component.scss'],
})
export class SplitScreenPageComponent
  extends BaseCreateComponent
  implements OnInit
{
  loaded = false;

  constructor(
    public override service: AppService,
    protected override route: ActivatedRoute,
    protected override router: Router,
    protected override dialog: MatDialog,
    private http: HttpClient,
    private donationsService: DonationsService,
    protected override leadsService: LeadsService,
    protected override localStorage: LocalStorageService,
    protected override designService: DesignService,
    public override responsiveService: ResponsiveService,
    @Inject(ENVIRONMENT) protected override environment: Environment
  ) {
    super(
      service,
      route,
      router,
      dialog,
      leadsService,
      localStorage,
      designService,
      responsiveService,
      FlowStep.SplitScreen,
      environment
    );
  }

  override ngOnInit(): void {
    if (
      this.flowType === FlowTypes.PARTNER ||
      (this.flowType === FlowTypes.TERRITORY &&
        !this.territoriesWithLanding.includes(this.flowId))
    ) {
      this.next();
    } else {
      super.ngOnInit();
    }
  }

  public override getCharity(id: string): void {
    if (
      this.service.charity &&
      (!this.service.charity.meta?.splitScreenConfig ||
        !!this.service.charity.meta.version)
    ) {
      this.setCharity(this.service.charity);
    } else {
      this.service.getCharity(id).subscribe({
        next: (charity) => {
          (charity.meta?.splitScreenConfig
            ? this.http.get(
                charity.meta?.splitScreenConfig + `?t=${new Date().getTime()}`
              )
            : of(charity.meta || {})
          )
            .pipe(
              catchError((err) => {
                return of({});
              }),
              take(1)
            )
            .subscribe((config) => {
              charity.meta = new CharityMeta({ ...charity.meta, ...config });
              this.setCharity(charity);
            });
        },
        error: () => {
          this.router.navigate(['/', 'page-not-found'], {
            queryParams: {
              message: `Charity #${id} could not be found!`,
              url: window.location.href,
            },
          });
        },
      });
    }
  }

  private setCharity(charity: Charity) {
    this.charity = charity;
    this.service.charity = charity;
    if (!this.service.pricing) {
      this.service.pricing = charity.market?.pricing;
    }
    if (!!this.charity.meta?.enabled) {
      this.loaded = true;
    } else {
      this.service.donation = undefined;
      this.service.charity = undefined;
      this.service.pricing = undefined;
      this.next();
    }
  }

  hideSplitScreen(
    res: { disassembly: boolean; stairs: boolean } = {
      disassembly: false,
      stairs: false,
    }
  ) {
    this.router.navigate(['/', 'spec', this.flowType, this.flowId], {
      queryParams: { ...this.route.snapshot.queryParams, ...res },
    });
  }

  override get valid(): boolean {
    return true;
  }

  override showError(): void {}

  override init(): void {}

  override next(): void {
    this.router.navigate(['/', 'spec', this.flowType, this.flowId], {
      queryParams: this.route.snapshot.queryParams,
    });
  }

  override saveData(): ButtonActivity {
    return ButtonActivity.SCREENING_PRIORITY;
  }
}
