import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from '@donor/app.service';
import { Charity, Organization } from '@domains';
import { Destructible } from '@rspl-ui';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent extends Destructible implements OnInit {
  @Input() title: string = '';
  @Input() subtitle?: string;
  @Input() hasBack = false;
  @Input() logo?: string = '';
  @Output() onBack = new EventEmitter();

  charity?: Charity | null;
  territory?: Organization | null;

  constructor(private service: AppService) {
    super();
  }

  ngOnInit(): void {
    this.service.charity$
      .pipe(takeUntil(this.destroy$))
      .subscribe((charity) => (this.charity = charity));

    this.service.territory$
      .pipe(takeUntil(this.destroy$))
      .subscribe((territory) => (this.territory = territory));
  }
}
