import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Charity } from '@domains';

@Component({
  selector: 'app-categorize-help-dialog',
  templateUrl: './categorize-help-dialog.component.html',
  styleUrls: ['./categorize-help-dialog.component.scss'],
})
export class CategorizeHelpDialogComponent {
  constructor(
    public reference: MatDialogRef<CategorizeHelpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: Charity
  ) {}
}
