import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ButtonActivity,
  Charity,
  Design,
  DonationSource,
  ENVIRONMENT,
  Environment,
  Lead,
  LeadChannel,
  LeadUTM,
  Pricing,
  Specification,
} from '@domains';
import { AppService } from '@donor/app.service';
import { LeadsService, LocalStorageService } from '@rspl-api';
import {
  ConfirmDialogComponent,
  DesignService,
  Designable,
  ResponsiveService,
} from '@rspl-ui';
import { OwnerType } from 'libs/domains/src/owner';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

export const LEAD_ID = 'rspl-lead-id';
export const LEAD_ID_TIMESTAMP = 'rspl-lead-id-timestamp';
export const LEAD_FLOW = 'rspl-lead-flow';
export const LEAD_FLOW_ID = 'rspl-lead-id-flow';

export enum FlowTypes {
  CHARITY = 'charity',
  PARTNER = 'partner',
  TERRITORY = 'territory',
}

export enum FlowStep {
  SplitScreen = 0,
  DonationDetails = 1,
  PlaceTime = 2,
  AdditionalInformation = 3,
  Payment = 4,
}

@Component({
  template: '',
})
export abstract class BaseCreateComponent extends Designable implements OnInit {
  flowType!: FlowTypes;
  flowTypes = FlowTypes;
  flowId!: string;
  marketingSource?: string;
  leadId?: string;
  lead?: Lead;
  errorVisible = false;
  dirty = false;
  isSubmitting = false;
  error?: string;
  charity?: Charity | null;
  territoriesWithLanding: string[];

  constructor(
    public service: AppService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected dialog: MatDialog,
    protected leadsService: LeadsService,
    protected localStorage: LocalStorageService,
    protected override designService: DesignService,
    public override responsiveService: ResponsiveService,
    @Inject(String) private flowStep: FlowStep,
    @Inject(ENVIRONMENT) protected environment: Environment
  ) {
    super(designService, responsiveService);
    if (this.route.snapshot.queryParams['m'] === 'true') {
      this.service.manualUrlParam = true;
    }
    this.marketingSource = this.route.snapshot.queryParams['utm_source'];
    this.flowId = this.route.snapshot.params['id'];
    this.flowType = this.route.snapshot.params['flowType'];
    this.territoriesWithLanding =
      this.environment.special.territoriesWithLanding;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    const urlLeadId = !!this.route.snapshot.queryParams['lead_id'];
    if (urlLeadId) {
      this.localStorage.removeItem(LEAD_ID);
      this.localStorage.removeItem(LEAD_FLOW);
      this.localStorage.removeItem(LEAD_FLOW_ID);
      this.localStorage.removeItem(LEAD_ID_TIMESTAMP);
    }
    const flowType = this.localStorage.getItem(LEAD_FLOW)
      ? (this.localStorage.getItem(LEAD_FLOW) as FlowTypes)
      : undefined;
    const flowId = this.localStorage.getItem(LEAD_FLOW_ID);
    if (
      (flowType && flowType !== this.flowType) ||
      (flowId && flowId !== this.flowId)
    ) {
      this.clearLead();
      location.reload();
      return;
    }
    if (this.flowType === FlowTypes.CHARITY && this.flowId) {
      this.getCharity(this.flowId);
      this.service.charity$
        .pipe(
          filter((charity) => !!charity),
          take(1)
        )
        .subscribe((charity) => {
          if (!this.charity) {
            this.charity = charity;
          }
          this.initLead(urlLeadId);
        });
    } else if (this.flowType === this.flowTypes.TERRITORY && this.flowId) {
      this.service.getTerritory(this.flowId).pipe(take(1)).subscribe();
      this.designService.setDesign(Design.DESIGN_2);
      this.initLead(urlLeadId);
    } else {
      this.designService.setDesign(Design.DESIGN_2);
      this.initLead(urlLeadId);
    }
  }

  initLead(urlLeadId: boolean) {
    this.leadId =
      this.route.snapshot.queryParams['lead_id'] ||
      this.localStorage.getItem(LEAD_ID)?.toString();
    let timestamp;
    const curr = moment();
    const tmp = this.leadId
      ? moment(this.localStorage.getItem(LEAD_ID_TIMESTAMP))
      : null;

    if (tmp) {
      timestamp = moment(tmp).add(1, 'hour');
    }
    if (!urlLeadId && (!timestamp || timestamp.isBefore(curr))) {
      this.clearLead();
    }
    this.localStorage.setItem(LEAD_FLOW, this.flowType);
    this.localStorage.setItem(LEAD_FLOW_ID, this.flowId);
    (this.service.donation
      ? of(this.service.donation)
      : this.leadId && urlLeadId
      ? this.service.getLead(this.leadId)
      : this.leadId
      ? this.service.getLead(this.leadId)
      : this.createLead()
    )
      .pipe(take(1))
      .subscribe({
        next: (lead) => {
          this.lead = lead;
          this.leadId = this.lead?.id;
          if (
            (this.flowType === FlowTypes.CHARITY &&
              this.flowId &&
              this.lead?.charityId !== this.flowId) ||
            (this.flowType === FlowTypes.PARTNER &&
              this.flowId &&
              this.lead?.partnerId !== this.flowId) ||
            !this.lead ||
            this.lead.donationCode
          ) {
            this.clearLead();
            this.reset();
            if (
              (this.flowType === FlowTypes.CHARITY &&
                this.flowId &&
                this.lead?.charityId !== this.flowId) ||
              (this.flowType === FlowTypes.PARTNER &&
                this.flowId &&
                this.lead?.partnerId !== this.flowId)
            ) {
              location.reload();
            }
          } else {
            this.checkStep();
          }
        },
        error: (err) => {
          if (this.route.snapshot.url.find((u) => u.path === 'card-on-file')) {
            this.router.navigate(['/', 'page-not-found'], {
              queryParams: {
                message: `Lead <b>${this.leadId}</b> could not be found!`,
                url: window.location.href,
              },
            });
            return;
          }
          if (err.status === 403) {
            this.clearLead();
            location.reload();
          }
          this.isSubmitting = false;
          this.error = err;
          return;
        },
      });
  }

  checkStep() {
    let step: FlowStep;
    if (
      !this.lead?.specification ||
      !this.lead?.pricing ||
      Pricing.getTotalBasePrice(
        this.lead?.specification,
        this.lead?.pricing
      ) === this.lead?.pricing?.base
    ) {
      step = FlowStep.DonationDetails;
    } else if (
      !this.lead.date ||
      !this.lead.partOfDay ||
      !this.lead.address ||
      !this.lead.address.zip
    ) {
      step = FlowStep.PlaceTime;
    } else if (
      !this.lead.donor?.email ||
      !this.lead.donor.name ||
      !this.lead.donor.phone
    ) {
      step = FlowStep.AdditionalInformation;
    } else {
      step = FlowStep.Payment;
    }
    if (this.flowStep <= step) {
      this.init();
    } else {
      const queryParams = this.route.snapshot.queryParams || {};

      let route: string[];
      switch (step) {
        case FlowStep.PlaceTime:
          route = ['/', 'place-time', this.flowType, this.flowId];
          break;
        case FlowStep.AdditionalInformation:
          route = ['/', 'additional-information', this.flowType, this.flowId];
          break;
        case FlowStep.Payment:
          route = ['/', 'confirm', this.flowType, this.flowId];
          break;
        case FlowStep.DonationDetails:
        default:
          route = ['/', 'start', this.flowType, this.flowId];
          break;
      }
      this.router.navigate(route, {
        queryParams,
      });
    }
  }

  getCharity(charityId: string) {
    if (!this.service.charity) {
      this.service.getCharity(charityId).pipe(take(1)).subscribe();
    }
  }

  getLead(leadId: string): Observable<Lead | undefined> {
    return this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'Continue Session',
          message:
            "Would you like to continue with your last donation pickup request, we've noticed you were already working on submitting a pickup request. Please select no, to start a fresh request, or please select yes, to resume where you left off",
        },
      })
      .afterClosed()
      .pipe(
        switchMap((confirmed) => {
          if (confirmed) {
            return this.service.getLead(leadId);
          } else {
            if (
              this.router.url.split('?')[0] ===
              '/' +
                this.initialRoute.splice(1, this.initialRoute.length).join('/')
            ) {
              return this.createLead();
            } else {
              this.reset();
              return of(undefined);
            }
          }
        }),
        catchError((error) => {
          this.clearLead();
          location.reload();
          return of();
        }),
        take(1)
      );
  }

  save(): void {
    this.dirty = true;
    if (!this.valid) {
      this.onError();
      return;
    }
    this.isSubmitting = true;
    this.errorVisible = false;
    this.service
      .createLeadActivity(this.lead?.id, this.saveData())
      .pipe(take(1))
      .subscribe();
    this.saveLead()
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.isSubmitting = false;
          this.next();
        },
        error: (err) => {
          this.isSubmitting = false;
          this.error = err;
        },
      });
  }

  reset() {
    const queryParams = this.route.snapshot.queryParams || {};
    delete queryParams['lead_id'];

    this.service.reset();
    this.router.navigate(this.initialRoute, {
      queryParams,
    });
  }

  get initialRoute() {
    return ['/', 'start', this.flowType, this.flowId];
  }

  private saveLead(): Observable<Lead> {
    return this.service.saveLead(this.service.donation).pipe(
      tap(() => {
        this.localStorage.setItem(LEAD_ID_TIMESTAMP, moment().toISOString());
      })
    );
  }

  private createLead(): Observable<Lead> {
    this.service.donation = {
      ...this.service.donation,
      charityId: this.flowType === FlowTypes.CHARITY ? this.flowId : undefined,
      partnerId: this.flowType === FlowTypes.PARTNER ? this.flowId : undefined,
      channel: LeadChannel.donor_app,
      marketingSource:
        (this.flowType === FlowTypes.CHARITY
          ? DonationSource.charity
          : this.flowType === FlowTypes.PARTNER
          ? DonationSource.partner
          : this.flowType === FlowTypes.TERRITORY && this.flowId !== '1'
          ? DonationSource.territory
          : DonationSource.resupply) +
        (this.marketingSource ? '-' + this.marketingSource?.toLowerCase() : ''),
      ...(this.charity?.meta?.onlySmallItems
        ? {
            specification: new Specification({
              small: 5,
            }),
          }
        : {}),
      meta: {
        source:
          this.flowType === FlowTypes.CHARITY
            ? DonationSource.charity
            : this.flowType === FlowTypes.PARTNER
            ? DonationSource.partner
            : this.flowType === FlowTypes.TERRITORY && this.flowId !== '1'
            ? DonationSource.territory
            : DonationSource.resupply,
        design: this.charity?.meta?.onlySmallItems
          ? 'v3'
          : this.design2
          ? 'v2'
          : undefined,
      },
    };
    if (
      this.route.snapshot.queryParams['rsp_ot'] &&
      this.route.snapshot.queryParams['rsp_oid']
    ) {
      this.service.donation.ownerId =
        this.route.snapshot.queryParams['rsp_oid'];
      this.service.donation.ownerType =
        this.route.snapshot.queryParams['rsp_ot'];
    } else {
      this.service.donation.ownerType =
        this.flowType === FlowTypes.CHARITY
          ? OwnerType.Charity
          : this.flowType === FlowTypes.PARTNER
          ? OwnerType.Partner
          : OwnerType.Organization;

      this.service.donation.ownerId = this.flowId;
    }
    this.service.donation.utm = new LeadUTM({
      source: this.route.snapshot.queryParams['utm_source'],
      medium: this.route.snapshot.queryParams['utm_medium'],
      campaign: this.route.snapshot.queryParams['utm_campaign'],
      term: this.route.snapshot.queryParams['utm_term'],
      content: this.route.snapshot.queryParams['utm_content'],
    });

    this.route.snapshot.queryParams['rsp_ot'];
    return this.saveLead().pipe(
      tap((lead) => {
        this.localStorage.setItem(LEAD_ID, lead.id || '');
        this.lead = lead;
        this.router.navigate(this.initialRoute, {
          queryParams: this.route.snapshot.queryParams,
        });
      })
    );
  }

  private onError() {
    this.errorVisible = true;
    this.showError();
  }

  private clearLead() {
    this.localStorage.removeItem(LEAD_ID);
    this.localStorage.removeItem(LEAD_FLOW);
    this.localStorage.removeItem(LEAD_FLOW_ID);
    this.localStorage.removeItem(LEAD_ID_TIMESTAMP);
    this.lead = undefined;
    this.leadId = undefined;
  }

  get charityId(): string | undefined {
    return this.flowType === FlowTypes.CHARITY ? this.flowId : undefined;
  }

  get partnerId(): string | undefined {
    return this.flowType === FlowTypes.PARTNER ? this.flowId : undefined;
  }

  abstract get valid(): boolean;

  abstract showError(): void;

  abstract init(): void;

  abstract saveData(): ButtonActivity;

  abstract next(): void;
}
